var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center pt-50 mb-2"},[_c('h2',{staticClass:"font-weight-bolder m-0"},[_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-50 text-danger bg-white",staticStyle:{"padding":"0.5px"},attrs:{"variant":"white"}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftCircleIcon","size":"35"}})],1)],1),_vm._v(" Detalles usuario ")])]),_c('b-card',[_c('validation-observer',{ref:"editUser"},[_c('b-form',{staticClass:"mt-2 auth-create-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[(_vm.userPrepare)?_c('ImageDropzone',{ref:"images",attrs:{"files":_vm.files}}):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Nombre',"label-for":"account-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":'Nombre'},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":'Apellidos',"label-for":"account-surname"}},[_c('b-form-input',{attrs:{"name":"surname","placeholder":'Apellidos'},model:{value:(_vm.user.surname),callback:function ($$v) {_vm.$set(_vm.user, "surname", $$v)},expression:"user.surname"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":{ required: _vm.required, email: _vm.email, emailUnique: _vm.id }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Email',"label-for":"account-e-mail"}},[_c('b-form-input',{attrs:{"name":"email","placeholder":'Email',"disabled":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":'Teléfono',"label-for":"account-phone"}},[_c('b-form-input',{attrs:{"name":"phone","placeholder":'Teléfono'},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Rol',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Rol',"label-for":"account-rols"}},[_c('v-select',{attrs:{"label":"name_translation","filterable":true,"searchable":true,"options":_vm.rolesList,"placeholder":"Selecciona un rol"},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.currentUser && _vm.currentUser.roles[0].name === 'super_admin')?_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"Cliente","rules":(_vm.role && (_vm.role.name === 'admin' || _vm.role.name === 'user')) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cliente","label-for":"account-clients"}},[_c('v-select',{attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectClients,"disabled":_vm.disabledClients,"placeholder":"Cliente"},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3542838477)})],1):_vm._e(),(_vm.role === 'super_admin')?_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('span',[_vm._v("Activar usuario")])])],1):_vm._e(),_c('b-col',{attrs:{"sm":"6"}},[(_vm.role && _vm.role.name === 'super_admin' && _vm.currentUser.roles[0].name === 'super_admin')?_c('b-form-checkbox',{staticClass:"d-inline-block mb-2 mt-1",attrs:{"value":true},model:{value:(_vm.user.notifications),callback:function ($$v) {_vm.$set(_vm.user, "notifications", $$v)},expression:"user.notifications"}},[_vm._v(" Recibir notificaciones ")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1 text-uppercase",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s('Guardar')+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }