<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span> Detalles usuario
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editUser">
        <b-form class="mt-2 auth-create-form" @submit.prevent="handleSubmit" @keydown.enter="handleSubmit">
          <b-row>
            <b-col cols="12" class="mb-2">
              <ImageDropzone v-if="userPrepare" ref="images" :files="files" />
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <validation-provider #default="{ errors }" name="Nombre" rules="required">
                <b-form-group :label="'Nombre'" label-for="account-name">
                  <b-form-input v-model="user.name" name="name" :placeholder="'Nombre'" />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-group :label="'Apellidos'" label-for="account-surname">
                <b-form-input v-model="user.surname" name="surname" :placeholder="'Apellidos'" />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <validation-provider #default="{ errors }" name="Email" :rules="{ required, email, emailUnique: id }">
                <b-form-group :label="'Email'" label-for="account-e-mail">
                  <b-form-input v-model="user.email" name="email" :placeholder="'Email'" disabled />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-group :label="'Teléfono'" label-for="account-phone">
                <b-form-input v-model="user.phone" name="phone" :placeholder="'Teléfono'" />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <validation-provider #default="{ errors }" :name="'Rol'" rules="required">
                <b-form-group :label="'Rol'" label-for="account-rols">
                  <v-select v-model="role" label="name_translation" :filterable="true" :searchable="true"
                    :options="rolesList" placeholder="Selecciona un rol" />
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if="currentUser && currentUser.roles[0].name === 'super_admin'" sm="6">
              <validation-provider #default="{ errors }" name="Cliente"
                :rules="(role && (role.name === 'admin' || role.name === 'user')) ? 'required' : ''">
                <b-form-group label="Cliente" label-for="account-clients">
                  <v-select v-model="client" label="name" :filterable="true" :searchable="true"
                    :options="selectClients" :disabled="disabledClients" placeholder="Cliente" />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6" v-if="role === 'super_admin'">
              <b-form-checkbox v-model="active" name="check-button" switch inline>
                <span>Activar usuario</span>
              </b-form-checkbox>
            </b-col>
            <b-col sm="6">
              <b-form-checkbox v-if="role && role.name === 'super_admin' && currentUser.roles[0].name === 'super_admin'"
                v-model="user.notifications" :value="true" class="d-inline-block mb-2 mt-1">
                Recibir notificaciones
              </b-form-checkbox>
            </b-col>
            <b-col cols="12 text-right">
              <b-button type="submit" variant="primary" class="mt-2 mr-1 text-uppercase">
                {{ 'Guardar' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BFormCheckbox, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ImageDropzone,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.params.id,
      role: {},
      companies: '',
      client: {},
      clients: [],
      files: [],
      profileFile: null,
      required,
      email,
      userPrepare: false,
      changePassword: false,
      disabledClients: false,
      active: false,
    }
  },
  computed: {
    ...mapGetters({
      selectRoles: 'users/getRoles',
      user: 'users/getUser',
      selectCompanies: 'companies/getListCompanies',
      selectClients: 'clients/getSelectClients',
      currentUser: 'auth/getUser',
      currentLanguage: 'languages/getCurrentLanguage'
    }),
    rolesList () {
      return this.selectRoles.map((role) => {
        if (role.display_name) {
          role.name_translation = role.display_name[this.currentLanguage];
        }
        return role
      })
    }
  },
  methods: {
    ...mapActions({
      edit: 'users/edit',
      getUser: 'users/getUser',
      getRoles: 'users/getRoles',
      searchClients: 'clients/selectClients',
    }),
    ...mapMutations({

    }),
    setData() {
      console.log('user data', this.user);
      if (this.user.avatar !== undefined && this.user.avatar !== null && this.user.avatar) {
        const avatar = {
          name: 'Avatar',
          url: this.user.avatar,
          urlDelete: `/users/delete_image/${this.user.id}`,
        }
        this.files = [avatar]
      }
      if (this.user.roles) {
        const role = this.user.roles[0]
        role.name_translation = role.display_name[this.currentLanguage]
        this.role = role
        if (role.name === 'admin' || role.name === 'user' || role.name === 'documental' || role.name === 'tec_client') {
          this.clearAdmins()
        } else {
          this.clearUsers()
        }
      }
      if (this.user.client) {
        this.client = this.user.client[0]
      }
      if (this.user.active === 1 || this.user.active === true) {
        this.active = true
      }
      this.searchClients()
      this.userPrepare = true
    },
    setClients() {
      if (this.user.clients) {
        this.clients = this.user.clients
      }
    },
    handleSubmit() {
      this.$refs.editUser.validate().then(success => {
        if (success) {
          const { id } = this.user
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, user: formData })
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end',
          });
        }
      })
    },
    createFormData() {
      const data = this.$refs.images.getFormData()
      if (this.role) {
        data.append('role', this.role.name)
        if (this.role.name != 'super_admin') {
          data.append('notifications', false)
        } else {
          data.append('notifications', this.user.notifications)
        }
      }
      data.append('name', this.user.name)
      data.append('surname', this.user.surname)
      data.append('email', this.user.email)
      data.append('phone', this.user.phone)
      if (this.active) {
        data.append('active', 1)
      } else {
        data.append('active', 0)
      }
      if (this.changePassword === true) {
        data.append('oldPassword', this.user.oldPassword)
        data.append('newPassword', this.user.newPassword)
        data.append('newPassword_confirmation', this.user.newPassword_confirmation)
      }

      if (this.client) {
        data.append('client_id', this.client.id)
      }

      return data
    },
    clearAdmins() {
      this.selectRoles.forEach((value, index) => {
        // quitar estos roles
        if (value.name === 'super_admin' || value.name === 'tec') {
          this.selectRoles.splice(index, 1)
        }
      })
    },
    clearUsers() {
      for (let i = 0; i < this.selectRoles.length; i++) {
        if (this.selectRoles[i].name === 'admin' || this.selectRoles[i].name === 'user' || this.selectRoles[i].name === 'documental' || this.selectRoles[i].name === 'tec_client') {
          this.selectRoles.splice(i, 1)
          i--
        }
      }
    },
  },
  async created() {
    await this.getRoles()
    await this.getUser(this.$route.params.id)
    await this.setData()
    await this.setClients()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
